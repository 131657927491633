<template>
    <b-modal
        id="ModalCreateProject"
        title="Créer un projet"
        size="lg"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        no-close-on-backdrop
        no-close-on-esc
        scrollable
    >
        <FormProject ref="formProjectCreate" :sit="sit" :format="format" />
        <template #modal-footer="{ cancel }">
            <b-button variant="outline-secondary" @click="cancel()">
                Annuler
            </b-button>
            <b-button variant="kalkin-2" @click="save">
                Enregistrer et quitter
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import FormProject from "./FormProject";
export default {
    components: {
        FormProject,
    },

    props: {
        sit: { type: String, required: true },
    },

    data: function() {
        return {
            format: {},
        };
    },

    methods: {
        async fetchFormat() {
            this.format = await this.$store.dispatch("kbsit/getFormat", {
                sit: this.sit,
            });
        },
        async save() {
            if (await this.$refs.formProjectCreate.save()) {
                this.$bvModal.hide("ModalCreateProject");
                this.$emit("saved");
            }
        },
    },

    async beforeMount() {
        await this.fetchFormat();
    },
};
</script>
