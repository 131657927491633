<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1><small>Projets SIT</small> {{ sit }}</h1>
            <b-button variant="kalkin-2" size="sm" v-b-modal.ModalCreateProject>
                <b-icon-plus-circle /> Nouveau projet
            </b-button>
        </div>

        <div class="k-page-body text-left">
            <TableProjects ref="tableProjects" :sit="sit" />
        </div>

        <ModalCreateProject :sit="sit" @saved="refreshTable" />
    </div>
</template>

<script>
import TableProjects from "@/components/misc/admin-kbsit/project/TableProjects";
import ModalCreateProject from "@/components/misc/admin-kbsit/project/ModalCreateProject";

export default {
    components: {
        TableProjects,
        ModalCreateProject,
    },

    data: function() {
        return {
            sit: this.$route.params.sit,
        };
    },

    methods: {
        refreshTable() {
            this.$refs.tableProjects.refreshTable();
        },
    },
};
</script>
