<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <div class="flex-grow-1 mr-4">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher un projet"
                        v-model="filter"
                        debounce="500"
                    />
                </b-input-group>
            </div>

            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    Projets par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="5">5</b-form-radio>
                        <b-form-radio :value="10">10</b-form-radio>
                        <b-form-radio :value="20">20</b-form-radio>
                        <b-form-radio :value="50">50</b-form-radio>
                        <b-form-radio :value="100">100</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <b-table
            ref="tableProjects"
            class="text-break text-center"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :per-page="perPage"
            sort-by="name"
            show-empty
            empty-text="Aucun projet"
            empty-filtered-text="Aucune projet trouvé"
            stacked="md"
            hover
            tbody-tr-class="pointer"
            @row-clicked="onProjectSelected"
            striped
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
            <template #cell(createdAt)="data">
                {{ data.item.createdAt | dateFromISO(true) }}
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sit: { type: String, required: true },
    },

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            fields: [
                {
                    key: "name",
                    label: "Nom du projet",
                    sortable: true,
                    tdClass: "text-kalkin-1 font-weight-bold text-left",
                },

                {
                    key: "itemCount",
                    label: "Fiches",
                    sortable: true,
                },
                {
                    key: "selectionCount",
                    label: "Sélections",
                    sortable: true,
                },
                {
                    key: "createdAt",
                    label: "Création",
                    sortable: true,
                },
            ],
            filter: "",
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "kbsit/countFSPProjects",
                {
                    sit: this.sit,
                    filter: `name substring ${this.filter}`,
                },
            );
        },

        async dataProvider(ctx) {
            const projects = await this.$store.dispatch(
                "kbsit/getFSPProjects",
                {
                    sit: this.sit,
                    filter: `name substring ${this.filter}`,
                    sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                    limit: this.perPage,
                    offset: this.perPage * (this.currentPage - 1),
                },
            );
            return projects;
        },

        onProjectSelected(project) {
            this.$router.push({
                name: "KBSITProject",
                params: { sit: this.sit, projectId: project.id },
            });
        },

        refreshTable() {
            if (this.$refs.tableProjects) {
                this.$refs.tableProjects.refresh();
            }
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
